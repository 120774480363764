























import Vue from 'vue';
import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ModelSelect } from 'vue-search-select';

import { ACL } from '@/modules/acl/acl-service';
import { ExportService } from '@/services/export-service';
import { TrashAreaWorking } from '@/services/api/report/trash-area/trash-area-working';
import { House } from '@/services/api/house';
import { AxiosResponse } from 'axios';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'trash-area-form2',
  components: {
    ModelSelect,
  },
  setup(props, { root }) {
    const { current, isCurrentGeneral } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);

    const filter: {
      street: {
        value?: string,
        text?: string,
      },
    } = reactive({
      street: {},
    });

    const filteredStreets = computed(() => ([
      {
        text: 'Название улицы',
      },
      ...data.streets.map(street => ({
        value: street,
        text: street,
      })),
    ]));

    const canExport = ACL.can('report_trash_area_export.form2');

    const data: {
      streets: Array<string>,
    } = reactive({
      streets: [],
    });

    const init = () => {
      const municipalityId = current.value ? current.value.id : 0;
      House.getStreets(municipalityId)
        .then((response: AxiosResponse<{
          streets: Array<string>
        }>) => {
          data.streets = response.data.streets;

          if (filter.street.value) {
            filter.street.text = data.streets.find((street: string) => street === filter.street.value);
          }
        });
    };
    init();
    watch(current, () => init());

    return {
      filter,
      current,
      isCurrentGeneral,
      canExport,
      filteredStreets,
      data,
      exportReport() {
        TrashAreaWorking.exportReport({
          municipalityId: current.value ? current.value.id : 0,
          street: filter.street.value ? filter.street.text : '',
        })
          .then((file: Blob) => {
            ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Форма 2.xlsx');
          })
          .catch(() => {});
      },
    };
  },
});
