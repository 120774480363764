import axios from '@/services/axios';
import { AxiosResponse } from 'axios';

export class TrashAreaWorking {
  static exportReport({
    municipalityId,
    street,
  }: {
    municipalityId?: number,
    street?: string,
  }): Promise<Blob> {
    const params: {
      // eslint-disable-next-line camelcase
      municipality_id?: number,
      street?: string,
    } = {};
    if (municipalityId) {
      params.municipality_id = municipalityId;
    }
    if (street) {
      params.street = street;
    }
    return axios.get('/report-trash-area/export/form2', { params, responseType: 'blob' })
      .then((response: AxiosResponse<Blob>) => {
        return response.data;
      });
  }
}
